import React, { useContext, useEffect, useState } from 'react';
import pt from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import uuid4 from 'uuid/v4';

import { AuthContext } from './AuthContext';
import DeleteButton from './DeleteButton';
import { EventsContext, fetchServices, saveService } from './EventsContext';
import styles from './ServiceTimeEdit.module.css';

const ServiceTimeEdit = ({ history, match: { params: { serviceId } } }) => {
  const [{ isAdmin }] = useContext(AuthContext);
  const [state, dispatch] = useContext(EventsContext);
  const [selectedService, setSelectedService] = useState();
  const [canSave, setCanSave] = useState(true);
  const [dayError, setDayError] = useState(false);
  const { services } = state;

  // Fetch the known services
  useEffect(() => {
    dispatch(fetchServices());
  }, [dispatch]);

  // Set the selected service for convenience if editing
  useEffect(() => {
    if (services.length > 1) {
      setSelectedService({ ...services.find(service => service.id === serviceId) });
    }
    if (serviceId === 'new') {
      const newService = { id: uuid4(), day: '', times: [] };
      services.push(newService);
      setSelectedService(newService);
    }
  }, [serviceId, services]);

  // Keep an empty time slot at the bottom of the times list for adding new times
  useEffect(() => {
    if (!selectedService) {
      return;
    }

    const lastTime = [...selectedService.times].pop();
    if (selectedService.times.length === 0 || (lastTime.name !== '' && lastTime.time !== '')) {
      const updatedService = {
        ...selectedService,
        times: [...selectedService.times, { id: uuid4(), name: '', time: '' }]
      };
      setSelectedService({ ...updatedService });
    }
  }, [selectedService]);

  // This is an admin only page
  if (!isAdmin) {
    history.push('/');
    return '';
  }


  const validateForm = () => {
    if (selectedService.day.trim().length === 0) {
      setDayError(true);
      return false;
    }
    setDayError(false);
    setCanSave(true);
    return true;
  };

  const onDelete = (id) => {
    const { times } = selectedService;
    const timeToDelete = times.findIndex(time => time.id === id);
    setSelectedService({
      ...selectedService,
      times: [...times.slice(0, timeToDelete), ...times.slice(timeToDelete + 1)]
    });
  };

  const onSave = async () => {
    setCanSave(false);
    if (!validateForm()) {
      return;
    }
    await dispatch(saveService(state, { ...selectedService,
      times: selectedService.times.filter(time => (time.name !== '' || time.time !== ''))
    }));
    history.goBack();
  };

  const onNameChange = (evt) => {
    const updatedService = { ...selectedService };
    updatedService.day = evt.target.value;
    validateForm();
    setSelectedService({ ...updatedService });
  };

  const onTimeChange = (evt, id, field) => {
    const times = [...selectedService.times];
    times.find(time => time.id === id)[field] = evt.target.value;
    setSelectedService({ ...selectedService, times });
  };

  if (!services.length || !selectedService) {
    return '';
  }

  return (
    <main className={styles.main}>
      <section className="content">
        <AppBar className="appbar">
          <Toolbar>
            <Typography variant="h6" className={styles.title}>Services</Typography>
            {canSave && <Button color="inherit" data-testid="saveButton" onClick={onSave}>Save</Button>}
          </Toolbar>
        </AppBar>
        <form className={styles.content}>
          <TextField
            inputProps={{ 'data-testid': 'serviceTimeNameInput' }}
            label="Service time name"
            value={selectedService.day}
            onChange={onNameChange}
            error={dayError}
          />
          <div className={styles.timesHeader}>Times</div>
          <ul data-testid="timesList">
            {selectedService.times.map(time => (
              <li className={styles.timesRow} key={time.id}>
                <TextField
                  className={styles.nameInput}
                  label="Event"
                  value={time.name}
                  onChange={evt => onTimeChange(evt, time.id, 'name')}
                />
                <TextField
                  className={styles.timeInput}
                  label="Time"
                  value={time.time}
                  onChange={evt => onTimeChange(evt, time.id, 'time')}
                />
                {(time.name !== '' || time.time !== '') ? (
                  <DeleteButton onClick={() => onDelete(time.id)} />
                ) : (
                  <div className={styles.placeholder} />
                )}
              </li>
            ))}
          </ul>
        </form>
      </section>
    </main>
  );
};

ServiceTimeEdit.propTypes = {
  history: pt.shape({
    goBack: pt.func,
    push: pt.func
  }).isRequired,
  match: pt.shape({
    params: pt.shape({
      serviceId: pt.string
    })
  }).isRequired
};

export default ServiceTimeEdit;
