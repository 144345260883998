import React, { useContext, useEffect, useState } from 'react';
import pt from 'prop-types';
import { Link } from 'react-router-dom';

import { AuthContext } from './AuthContext';
import ConfirmDialog from './ConfirmDialog';
import DeleteButton from './DeleteButton';
import EditButton from './EditButton';
import MobileMenu from './MobileMenu';

import styles from './SermonList.module.css';
import { deleteSermon, fetchSermons, SermonContext } from './SermonContext';


const SermonList = ({ history }) => {
  const [{ isAdmin }] = useContext(AuthContext);
  const [state, dispatch] = useContext(SermonContext);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const { sermons } = state;

  useEffect(() => {
    dispatch(fetchSermons());
  }, [dispatch]);

  const onCancel = () => setConfirmDelete(null);

  const onDeleteClick = id => {
    setConfirmDelete(id);
  };

  const onDelete = () => {
    setConfirmDelete(null);
    dispatch(deleteSermon(confirmDelete));
    dispatch(fetchSermons());
  };

  const onEdit = id => {
    history.push(`/admin/edit/sermon/${id}`);
  };

  const onNew = () => {
    history.push('/admin/edit/sermon/new');
  };

  // This is needed until react-snap supports excluding routes
  /* istanbul ignore next */
  if (navigator.userAgent === 'ReactSnap') {
    return '';
  }

  return (
    <article className={styles.sermonList}>
      <section className="content">
        <header>
          <div>
            <span className={styles.brand}>Tessentee Baptist Church</span>
            <h1>Online Sermons</h1>
          </div>
          {isAdmin ? (
            <button type="button" className={styles.newButton} onClick={onNew} data-testid="newButton">New</button>
          ) : (
            <MobileMenu />
          )}
        </header>
        <ul className={styles.content}>
          {sermons.map(sermon => (
            <li key={sermon.id}>
              {isAdmin && (
                <div className={styles.actionBox}>
                  <EditButton editStyle={styles.editButton} onClick={() => onEdit(sermon.id)} />
                  <DeleteButton onClick={() => onDeleteClick(sermon.id)} />
                </div>
              )}
              <Link to={`/sermons/${sermon.id}`} className={styles.playButton}>Click to listen to {sermon.name}</Link>
              <div className={styles.description}>
                <span className={styles.title}>{sermon.name}</span>
                <div className={styles.tagline}>
                  <div className={styles.passages}>{sermon.passages.map((p, i) => (
                    i === 0 ? `${p}` : `, ${p}`
                  ))}
                  </div>
                  <div className={styles.date}>{sermon.date}</div>
                </div>
              </div>
            </li>
          ))}
        </ul>
        {(confirmDelete !== null) && (
          <ConfirmDialog title="Delete sermon?" confirmText="Delete" onConfirm={onDelete} onCancel={onCancel} />
        )}
      </section>
    </article>
  );
};

SermonList.propTypes = {
  history: pt.shape({
    push: pt.func
  }).isRequired
};

export default SermonList;
