import React, { useContext, useEffect } from 'react';
import pt from 'prop-types';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import { AuthContext } from './AuthContext';
import EditButton from './EditButton';
import { EventsContext, fetchEvents, fetchServices } from './EventsContext';
import styles from './Events.module.css';

const Events = ({ history }) => {
  const [{ isAdmin }] = useContext(AuthContext);
  const [state, dispatch] = useContext(EventsContext);
  const { events, services } = state;

  useEffect(() => {
    dispatch(fetchServices());
    dispatch(fetchEvents(false));
  }, [dispatch]);

  const onEditServices = () => {
    history.push('/admin/edit/service/');
  };

  const onEditEvents = () => {
    history.push('/admin/edit/event/');
  };

  return (
    <article id="events" className={styles.events}>
      <section className="content">
        <h3>
          Service Times
          {isAdmin && <EditButton onClick={onEditServices} />}
        </h3>
        {services.map(service => (
          <section key={service.day}>
            <h4>{service.day}</h4>
            {service.times.map(event => (
              <div key={event.name} className={styles.timeslot}>
                <span>{event.name}</span> <span>{event.time}</span>
              </div>
            ))}
          </section>
        ))}

        <h3 className={styles.eventsHeader}>
          Events
          {isAdmin && <EditButton onClick={onEditEvents} />}
        </h3>
        {events.map(event => {
          const eventDate = parseISO(event.date);
          return (
            <section key={event.id} className={styles.eventEntry}>
              <div className={styles.timeBlock}>
                <span className={styles.day}>{format(eventDate, 'E')}</span>
                <span className={styles.date}>{format(eventDate, 'MMM d')}</span>
                <span className={styles.time}>{event.time}</span>
              </div>
              <div className={styles.descriptionBlock}>
                <span className={styles.title}>{event.name}</span>
                <span className={styles.description}>{event.description}</span>
              </div>
            </section>
          );
        })}
      </section>
    </article>
  );
};

Events.propTypes = {
  history: pt.shape({
    push: pt.func
  }).isRequired,
};

export default Events;
