import React from 'react';
import pt from 'prop-types';

import { ReactComponent as TrashIcon } from './trash.svg';
import styles from './DeleteButton.module.css';

const DeleteButton = ({ onClick }) => (
  <div className={styles.deleteButton}>
    <button type="button" data-testid="deleteButton" onClick={onClick}><TrashIcon /></button>
  </div>
);

DeleteButton.propTypes = {
  onClick: pt.func.isRequired
};

export default DeleteButton;
