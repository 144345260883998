import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import pt from 'prop-types';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { AuthContext } from './AuthContext';
import ConfirmDialog from './ConfirmDialog';
import DeleteButton from './DeleteButton';
import { EventsContext, deleteService, fetchServices, saveServices } from './EventsContext';
import styles from './ServiceEdit.module.css';

const SortableContainer = sortableContainer(({ children, ...rest }) => <ul {...rest}>{children}</ul>);

const SortableItem = sortableElement(({ children, ...rest }) => <li {...rest}>{children}</li>);

const ServiceEdit = ({ history }) => {
  const [{ isAdmin }] = useContext(AuthContext);
  const [state, dispatch] = useContext(EventsContext);
  const [confirmDelete, setConfirmDelete] = useState(null);
  // Keep a local copy of services for instant feedback when re-ordering
  const [localServices, setLocalServices] = useState([]);

  const { services } = state;

  // Fetch the services on load
  useEffect(() => {
    dispatch(fetchServices());
  }, [dispatch]);

  // Sync local services with what the server says they should be on service update
  useEffect(() => {
    setLocalServices(services);
  }, [services]);

  // Page is admin only
  if (!isAdmin) {
    history.push('/');
    return '';
  }

  const onCancel = () => setConfirmDelete(null);

  const onDeleteClick = id => {
    setConfirmDelete(id);
  };

  const onDelete = () => {
    setConfirmDelete(null);
    dispatch(deleteService(state, confirmDelete));
    dispatch(fetchServices());
  };

  const onNew = () => {
    history.push('/admin/edit/service/new');
  };

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    const orderedServices = arrayMove(services, oldIndex, newIndex);
    // This will update the order immediately pending the save to the server
    setLocalServices(orderedServices);
    await dispatch(saveServices(orderedServices));
    dispatch(fetchServices());
  };

  return (
    <main className={styles.main}>
      <section className="content">
        <AppBar className="appbar">
          <Toolbar>
            <Typography variant="h6" className={styles.title}>Services</Typography>
            <Button color="inherit" onClick={onNew} data-testid="newButton">New</Button>
          </Toolbar>
        </AppBar>
        <section className={styles.content}>
          <SortableContainer className={styles.itemContainer} pressDelay={200} onSortEnd={onSortEnd}>
            {localServices.map((service, idx) => (
              <SortableItem index={idx} className={styles.serviceItem} key={service.day}>
                <div className={styles.mainItem}>
                  <Link to={`/admin/edit/service/${service.id}/`}>{service.day}</Link>
                  <DeleteButton onClick={() => onDeleteClick(service.id)} />
                </div>
                {service.times.map(time => (
                  <div
                    key={time.name + time.time}
                    className={styles.subItem}
                  >
                    <span>{time.name}</span>
                    <span>{time.time}</span>
                  </div>
                ))}
              </SortableItem>
            ))}
          </SortableContainer>
        </section>
        {(confirmDelete !== null) && (
          <ConfirmDialog title="Delete service?" confirmText="Delete" onConfirm={onDelete} onCancel={onCancel} />
        )}
      </section>
    </main>
  );
};

ServiceEdit.propTypes = {
  history: pt.shape({
    push: pt.func
  }).isRequired,
};

export default ServiceEdit;
