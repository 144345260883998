import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './TopNav.module.css';

const TopNav = () => {
  const [classname, setClassname] = useState(styles.hidden);

  useEffect(() => {
    const onScroll = () => {
      setClassname(window.pageYOffset > 80 ? '' : styles.hidden);
    };

    onScroll();
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [setClassname]);

  return (
    <nav className={`${styles.topnav} ${classname}`}>
      <a href="#directions">Directions</a>
      <a href="#events">Service Times</a>
      <Link to="/sermons">Sermons</Link>
      <a href="#aboutus">About Us</a>
    </nav>
  );
};

export default TopNav;
