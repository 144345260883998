/* eslint-disable react/no-danger */
import React, { useContext, useState } from 'react';
import marked from 'marked';
import { sanitize } from 'dompurify';

import { AuthContext } from './AuthContext';
import { CopyContext } from './CopyContext';
import CopyEdit from './CopyEdit';
import EditButton from './EditButton';
import styles from './Privacy.module.css';

const Privacy = () => {
  const [{ isAdmin }] = useContext(AuthContext);
  const [state,, actions] = useContext(CopyContext);
  const [isEditingCopy, setEditingCopy] = useState(false);
  const { privacy } = state;

  const onSave = (copy) => {
    actions.updateCopy('privacy', copy);
    setEditingCopy(false);
  };

  return (
    <main className={styles.privacy}>
      <section className="content">
        {isAdmin && <EditButton onClick={() => setEditingCopy(true)} />}
        <div dangerouslySetInnerHTML={{ __html: sanitize(marked(privacy)) }} />
        {isAdmin && isEditingCopy && (
          <CopyEdit
            copySection="privacy"
            open
            onClose={() => setEditingCopy(false)}
            onSave={onSave}
          />
        )}
      </section>
    </main>
  );
};

export default Privacy;
