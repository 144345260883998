import React, { useEffect, useRef, useState } from 'react';
import pt from 'prop-types';

import LinearProgress from '@material-ui/core/LinearProgress';

import ConfirmDialog from './ConfirmDialog';
import DeleteButton from './DeleteButton';
import styles from './UploadWidget.module.css';

const UploadWidget = ({ children, filename, onDelete, onUpload, path, progress }) => {
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const inputRef = useRef();

  useEffect(() => {
    if (filename && path) {
      setUploadedFile({ name: filename, url: path });
    }
  }, [filename, path]);

  const onCancel = () => setConfirmDelete(false);

  const onDeleteClick = () => {
    setConfirmDelete(true);
  };

  const onConfirmedDelete = () => {
    onDelete(uploadedFile.name);
    setConfirmDelete(false);
    setUploadedFile(null);
    setSelectedFile(null);
  };

  const handleFiles = evt => {
    const file = evt.target.files[0];
    setSelectedFile(file);
    onUpload(file);
  };

  if (uploadedFile) {
    return (
      <div className={`${styles.uploadWidget} ${styles.complete}`}>
        <span>{uploadedFile.name}</span>
        <DeleteButton onClick={onDeleteClick} />
        {confirmDelete && (
          <ConfirmDialog
            title="Delete upload?"
            confirmText="Delete"
            onConfirm={onConfirmedDelete}
            onCancel={onCancel}
          />
        )}
      </div>
    );
  }

  if (!selectedFile || progress === null) {
    return (
      <button
        data-testid="uploadButton"
        type="button"
        className={styles.uploadWidget}
        onClick={() => inputRef.current.click()}
      >
        { children }
        <input
          data-testid="uploadInput"
          ref={inputRef}
          accept="video/*,audio/*"
          type="file"
          onChange={handleFiles}
          style={{ display: 'none' }}
        />
      </button>
    );
  }

  return (
    <div className={`${styles.uploadWidget} ${styles.progress}`}>
      <div>Uploading {selectedFile.name}...</div>
      <LinearProgress variant="determinate" value={progress} margin="dense" />
    </div>
  );
};

UploadWidget.propTypes = {
  children: pt.node.isRequired,
  filename: pt.string,
  onDelete: pt.func.isRequired,
  onUpload: pt.func.isRequired,
  path: pt.string,
  progress: pt.number
};

UploadWidget.defaultProps = {
  filename: null,
  path: null,
  progress: 0
};
export default UploadWidget;
