/* eslint-disable react/no-danger */
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import marked from 'marked';
import { sanitize } from 'dompurify';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import styles from './LatestSermons.module.css';
import { AuthContext } from './AuthContext';
import { CopyContext } from './CopyContext';
import CopyEdit from './CopyEdit';
import EditButton from './EditButton';
import { fetchSermons, SermonContext } from './SermonContext';

const LatestSermons = () => {
  const [{ isAdmin }] = useContext(AuthContext);
  const [state,, actions] = useContext(CopyContext);
  const [sermonState, dispatch] = useContext(SermonContext);
  const [isEditingCopy, setEditingCopy] = useState(false);

  const { sermons } = state;
  const { sermons: sermonList } = sermonState;

  useEffect(() => {
    dispatch(fetchSermons(3));
  }, [dispatch]);

  const onSave = (copy) => {
    actions.updateCopy('sermons', copy);
    setEditingCopy(false);
  };

  const renderSermons = () => {
    // This is needed until react-snap supports excluding routes
    /* istanbul ignore next */
    if (navigator.userAgent === 'ReactSnap') {
      return '';
    }

    return sermonList.map(sermon => (
      <li className={styles.sermonEntry} key={sermon.id}>
        <a href={`/sermons/${sermon.id}`}>
          <span className={styles.title}>{sermon.name}</span>
          <span className={styles.date}>{format(parseISO(sermon.date), 'MMM d')}</span>
        </a>
      </li>
    ));
  };

  return (
    <article id="sermons" className={styles.sermons}>
      <section className="content">
        {isAdmin && <EditButton onClick={() => setEditingCopy(true)} />}
        <h3>Latest Sermons</h3>
        <section className={styles.blurb} dangerouslySetInnerHTML={{ __html: sanitize(marked(sermons)) }} />
        <ul>{renderSermons()}</ul>
        <div className={styles.more}>
          <Link to="/sermons">More sermons...</Link>
        </div>
        {isAdmin && isEditingCopy && (
          <CopyEdit
            copySection="sermons"
            open
            onClose={() => setEditingCopy(false)}
            onSave={onSave}
          />
        )}
      </section>
    </article>
  );
};

export default LatestSermons;
