import React from 'react';
import pt from 'prop-types';

import styles from './Passage.module.css';

const Passage = ({ passage: passageContent }) => {
  const { passage, verses } = passageContent;
  return (
    <section className={styles.passage}>
      <h3>{passage}</h3>
      {verses.map(v => <p key={v.number}><span className={styles.verseNumber}>{v.number}</span> {v.verse}</p>)}
    </section>
  );
};

export default Passage;

Passage.propTypes = {
  passage: pt.shape({
    book: pt.string,
    chapter: pt.number,
    verses: pt.array
  }).isRequired
};
