import React, { useCallback, useReducer } from 'react';
import firebase from 'firebase/app';
import pt from 'prop-types';
import format from 'date-fns/format';

export const INITIAL_STATE = {
  events: [],
  selectedEvent: null,
  services: []
};

export const EventsContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'receive_events':
      return { ...state, events: action.events };
    case 'receive_selected_event':
      return { ...state, selectedEvent: action.event };
    case 'receive_services':
      return { ...state, services: action.services };
    /* istanbul ignore next */
    default:
      /* istanbul ignore next */
      return state;
  }
};

export const deleteService = (state, id) => () => {
  const { services } = state;
  const idx = services.findIndex(s => s.id === id);
  /* istanbul ignore next */
  if (idx < 0) {
    return Promise.resolve();
  }
  return firebase.firestore().collection('services').doc('serviceList').set({
    services: [...services.slice(0, idx), ...services.slice(idx + 1)]
  });
};

export const fetchServices = () => async (dispatch) => {
  const doc = await firebase.firestore().collection('services').doc('serviceList').get();
  dispatch({ type: 'receive_services', services: doc.data().services });
};

export const saveService = (state, service) => async () => {
  // Find the service in the list of services
  const services = [...state.services];
  const idx = services.findIndex(s => s.id === service.id);
  /* istanbul ignore else */
  if (idx >= 0) {
    services[idx] = service;
  }
  await firebase.firestore().collection('services').doc('serviceList').set({ services });
};

export const saveServices = (services) => () => (
  firebase.firestore().collection('services').doc('serviceList').set({ services })
);

export const fetchEvent = eventId => async (dispatch) => {
  const doc = await firebase.firestore().collection('events').doc(eventId).get();
  dispatch({ type: 'receive_selected_event', event: doc.data() });
};

export const fetchEvents = (allowPastEvents = true) => async (dispatch) => {
  let colRef = firebase.firestore().collection('events').orderBy('date').orderBy('time');
  if (!allowPastEvents) {
    colRef = colRef.where('date', '>=', format(new Date(), 'yyyy-MM-dd'));
  }
  const col = await colRef.get();
  const events = [];
  col.forEach(event => events.push({ id: event.id, ...event.data() }));
  dispatch({ type: 'receive_events', events });
};

export const deleteEvent = (id) => () => firebase.firestore().collection('events').doc(id).delete();

export const saveEvent = (id, event) => () => {
  if (id === 'new') {
    return firebase.firestore().collection('events').add(event);
  }
  return firebase.firestore().collection('events').doc(id).set(event);
};

const getThunkedDispatch = dispatch => {
  const thunkedDispatch = action => {
    if (typeof action === 'function') {
      return action(thunkedDispatch);
    }
    return dispatch(action);
  };
  return thunkedDispatch;
};

export const EventsProvider = ({ children }) => {
  const [state, reactDispatch] = useReducer(reducer, INITIAL_STATE);
  const dispatch = useCallback(getThunkedDispatch(reactDispatch), [reactDispatch]);

  return (
    <EventsContext.Provider value={[state, dispatch]}>
      {children}
    </EventsContext.Provider>
  );
};

EventsProvider.propTypes = {
  children: pt.element.isRequired
};
