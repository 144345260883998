import React, { useContext, useEffect, useState } from 'react';
import pt from 'prop-types';

import format from 'date-fns/format';
import parse from 'date-fns/parse';
import parseISO from 'date-fns/parseISO';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { DatePicker, TimePicker } from '@material-ui/pickers';

import { AuthContext } from './AuthContext';
import { EventsContext, fetchEvent, saveEvent } from './EventsContext';
import styles from './EventsEventEdit.module.css';

const DATE_FORMAT = 'yyyy-MM-dd';
const TIME_FORMAT = 'h:mm a';

const EventsEventEdit = ({ history, match: { params: { eventId } } }) => {
  const [{ isAdmin }] = useContext(AuthContext);
  const [state, dispatch] = useContext(EventsContext);
  const [canSave, setCanSave] = useState(true);
  const [updatedEvent, setUpdatedEvent] = useState();
  const [nameError, setNameError] = useState(false);
  const { selectedEvent } = state;

  // Fetch the event
  useEffect(() => {
    if (eventId !== 'new') {
      dispatch(fetchEvent(eventId));
    }
  }, [dispatch, eventId]);

  useEffect(() => {
    if (selectedEvent) {
      setUpdatedEvent({ ...selectedEvent });
    }
    if (eventId === 'new') {
      setUpdatedEvent({
        date: format(new Date(), DATE_FORMAT),
        description: '',
        name: '',
        time: format(new Date(), TIME_FORMAT)
      });
    }
  }, [eventId, selectedEvent]);

  // This is an admin only page
  if (!isAdmin) {
    history.push('/');
    return '';
  }

  const validateForm = () => {
    setCanSave(true);
    if (updatedEvent.name.trim().length === 0) {
      setNameError(true);
      return false;
    }
    return true;
  };

  const onSave = async () => {
    setCanSave(false);
    if (!validateForm()) {
      return;
    }
    await dispatch(saveEvent(eventId, updatedEvent));
    history.goBack();
  };

  const onNameChange = (evt) => {
    updatedEvent.name = evt.target.value;
    validateForm();
    setUpdatedEvent({ ...updatedEvent });
  };

  const onUpdateDate = date => {
    updatedEvent.date = format(date, DATE_FORMAT);
    validateForm();
    setUpdatedEvent({ ...updatedEvent });
  };

  const onUpdateTime = time => {
    updatedEvent.time = format(time, TIME_FORMAT);
    validateForm();
    setUpdatedEvent({ ...updatedEvent });
  };

  const onDescriptionChange = (evt) => {
    updatedEvent.description = evt.target.value;
    validateForm();
    setUpdatedEvent({ ...updatedEvent });
  };

  if (!updatedEvent) {
    return '';
  }

  return (
    <main className={styles.main}>
      <section className="content">
        <AppBar className="appbar">
          <Toolbar>
            <Typography variant="h6" className={styles.title}>Edit Event</Typography>
            {canSave && <Button color="inherit" data-testid="saveButton" onClick={onSave}>Save</Button>}
          </Toolbar>
        </AppBar>
        <form className={styles.content}>
          <TextField
            inputProps={{ 'data-testid': 'eventNameInput' }}
            label="Event name"
            value={updatedEvent.name}
            onChange={onNameChange}
            error={nameError}
          />
          <DatePicker
            inputProps={{ 'data-testid': 'dateInput' }}
            label="Date"
            format="yyyy-MM-dd"
            value={parseISO(updatedEvent.date)}
            onChange={onUpdateDate}
          />
          <TimePicker
            inputProps={{ 'data-testid': 'timeInput' }}
            label="Time"
            format="p"
            value={parse(updatedEvent.time, 'h:mm a', new Date())}
            onChange={onUpdateTime}
          />
          <TextField
            inputProps={{ 'data-testid': 'descriptionField' }}
            label="Description"
            fullWidth
            multiline
            rowsMax="14"
            rows="8"
            value={updatedEvent.description}
            onChange={onDescriptionChange}
          />
        </form>
      </section>
    </main>
  );
};

EventsEventEdit.propTypes = {
  history: pt.shape({
    goBack: pt.func,
    push: pt.func
  }).isRequired,
  match: pt.shape({
    params: pt.shape({
      eventId: pt.string
    })
  }).isRequired
};

export default EventsEventEdit;
