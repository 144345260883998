import React, { useContext } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import FirebaseAuth from 'react-firebaseui/FirebaseAuth';

import { AuthContext } from './AuthContext';
import styles from './Admin.module.css';

const uiConfig = {
  signInSuccessUrl: '/admin/',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID
  ]
};

const Admin = () => {
  const [{ isAdmin, photoUrl }] = useContext(AuthContext);

  const signOut = () => {
    firebase.auth().signOut();
  };

  if (isAdmin) {
    return (
      <article className={styles.admin}>
        <section className="content">
          <header>
            <span className={styles.brand}>
              <img className={styles.avatar} src={photoUrl} alt="avatar" />
              TBC Admin
            </span>
            <span><button type="button" onClick={signOut}>LOGOUT</button></span>
          </header>
          <main />
          <footer>
            <a href="/">Go to site</a>
          </footer>
        </section>
      </article>
    );
  }
  return (
    <article className={styles.admin}>
      <section className="content">
        <header>
          <span>TBC Admin</span>
        </header>
        <main>
          <FirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
        </main>
        <footer>
          <a href="/">Go to site</a>
        </footer>
      </section>
    </article>
  );
};

export default Admin;
