import React from 'react';
import pt from 'prop-types';
import { Link } from 'react-router-dom';
import format from 'date-fns/format';

import styles from './App.module.css';
import AboutUs from './AboutUs';
import Directions from './Directions';
import Events from './Events';
import MainHeader from './MainHeader';
import LatestSermons from './LatestSermons';
import TopNav from './TopNav';


const App = ({ history }) => (
  <div className={styles.app}>
    <TopNav />
    <MainHeader />
    <Directions />
    <Events history={history} />
    <LatestSermons />
    <AboutUs />
    <footer className={styles.footer}>
      <div>
        <div>Copyright {format(new Date(), 'yyyy')}, Tessentee Baptist Church</div>
        <div><Link to="/privacy">Privacy policy</Link></div>
      </div>
    </footer>
  </div>
);

App.propTypes = {
  history: pt.shape({
    push: pt.func
  }).isRequired,
};

export default App;
