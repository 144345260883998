/* eslint-disable max-len,react/no-danger */
import React, { useContext, useState } from 'react';
import marked from 'marked';
import { sanitize } from 'dompurify';

import styles from './Directions.module.css';
import { AuthContext } from './AuthContext';
import { CopyContext } from './CopyContext';
import CopyEdit from './CopyEdit';
import EditButton from './EditButton';

const Directions = () => {
  const [{ isAdmin }] = useContext(AuthContext);

  const [state,, actions] = useContext(CopyContext);
  const { directions } = state;
  const [isEditingCopy, setEditingCopy] = useState(false);

  const onSave = (copy) => {
    actions.updateCopy('directions', copy);
    setEditingCopy(false);
  };

  return (
    <article id="directions" className={styles.directions}>
      <section className="content">
        {isAdmin && <EditButton onClick={() => setEditingCopy(true)} />}
        <p dangerouslySetInnerHTML={{ __html: sanitize(marked(directions)) }} />
        <iframe
          title="Directions by Google Maps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7919.919588008578!2d-83.33802952577207!3d35.07166931888683!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x20380763ade9848a!2sTessentee+Church!5e0!3m2!1sen!2sus!4v1542472509044"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen
        />
        {isAdmin && isEditingCopy && (
          <CopyEdit
            copySection="directions"
            open
            onClose={() => setEditingCopy(false)}
            onSave={onSave}
          />
        )}
      </section>
    </article>
  );
};

export default Directions;
