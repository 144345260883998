import React, { useContext, useEffect, useState } from 'react';
import pt from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import { CopyContext } from './CopyContext';
import styles from './CopyEdit.module.css';

const CopyEdit = ({ copySection, open, onClose, onSave }) => {
  const [copy] = useContext(CopyContext);
  const [newCopy, setNewCopy] = useState(copy[copySection]);

  useEffect(() => {
    setNewCopy(copy[copySection]);
  }, [copy, copySection]);

  const onChange = evt => {
    setNewCopy(evt.target.value);
  };

  const onCancel = () => {
    onClose();
  };

  return (
    <Dialog data-testid="copyEdit" className={styles.copyEdit} fullWidth open={open}>
      <DialogTitle>Edit &quot;{copySection}&quot; copy</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Edit copy below.&nbsp;
          <a
            href="https://www.markdownguide.org/basic-syntax"
            target="_blank"
            rel="noopener noreferrer"
          >
            Markdown
          </a> format is supported.
        </DialogContentText>
        <TextField
          inputProps={{ 'data-testid': 'copyTextField' }}
          fullWidth
          multiline
          rowsMax="14"
          rows="8"
          value={newCopy}
          onChange={onChange}
        />
      </DialogContent>
      <DialogActions>
        <Button data-testid="cancelButton" color="primary" onClick={onCancel}>Cancel</Button>
        <Button data-testid="saveButton" color="primary" onClick={() => onSave(newCopy)}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

CopyEdit.propTypes = {
  copySection: pt.string.isRequired,
  open: pt.bool.isRequired,
  onClose: pt.func.isRequired,
  onSave: pt.func.isRequired
};

export default CopyEdit;
