/* eslint-disable jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './MobileMenu.module.css';

const MobileMenu = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <nav className={styles.mobileMenu}>
      <input type="checkbox" id="openMenu" checked={isVisible} onChange={toggleVisibility} data-testid="open" />
      <label htmlFor="openMenu">
        <div className={styles.bar} />
        <div className={styles.bar} />
        <div className={styles.bar} />
      </label>
      <ul className={styles.menu}>
        <li><a href="#directions" onClick={toggleVisibility}>Directions</a></li>
        <li><a href="#events" onClick={toggleVisibility}>Service Times</a></li>
        <li><Link to="/sermons">Sermons</Link></li>
        <li><a href="#aboutus" onClick={toggleVisibility}>About Us</a></li>
      </ul>
    </nav>
  );
};

export default MobileMenu;
