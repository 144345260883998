import React, { useEffect, useMemo, useState } from 'react';
import firebase from 'firebase/app';
import pt from 'prop-types';

export const INITIAL_STATE = {
  about: '',
  directions: '',
  privacy: '',
  sermons: ''
};

export const CopyContext = React.createContext();

export const CopyProvider = ({ children }) => {
  const [state, setState] = useState(INITIAL_STATE);

  const actions = useMemo(() => ({
    async updateCopy(copySection, copy) {
      await firebase.firestore().collection('copy').doc(copySection).update({
        content: copy
      });
      setState(prevState => ({ ...prevState, [copySection]: copy }));
    }
  }), []);

  useEffect(() => {
    const fetchCopy = () => (
      Promise.all([
        firebase.firestore().collection('copy').doc('about').get(),
        firebase.firestore().collection('copy').doc('directions').get(),
        firebase.firestore().collection('copy').doc('privacy').get(),
        firebase.firestore().collection('copy').doc('sermons').get()
      ])
        .then((data) => {
          setState({
            about: data[0].get('content'),
            directions: data[1].get('content'),
            privacy: data[2].get('content'),
            sermons: data[3].get('content')
          });
        })
    );
    fetchCopy();
  }, [setState]);

  return (
    <CopyContext.Provider value={[state, setState, actions]}>
      {children}
    </CopyContext.Provider>
  );
};

CopyProvider.propTypes = {
  children: pt.element.isRequired
};
