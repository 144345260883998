import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import pt from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { AuthContext } from './AuthContext';
import ConfirmDialog from './ConfirmDialog';
import DeleteButton from './DeleteButton';
import { EventsContext, deleteEvent, fetchEvents } from './EventsContext';
import styles from './EventsEdit.module.css';


const EventsEdit = ({ history }) => {
  const [{ isAdmin }] = useContext(AuthContext);
  const [state, dispatch] = useContext(EventsContext);
  const [confirmDelete, setConfirmDelete] = useState(null);

  const { events } = state;

  // Fetch the events on load
  useEffect(() => {
    dispatch(fetchEvents());
  }, [dispatch]);

  // Page is admin only
  if (!isAdmin) {
    history.push('/');
    return '';
  }

  const onCancel = () => setConfirmDelete(null);

  const onDeleteClick = id => {
    setConfirmDelete(id);
  };

  const onDelete = () => {
    setConfirmDelete(null);
    dispatch(deleteEvent(confirmDelete));
    dispatch(fetchEvents());
  };

  const onNew = () => {
    history.push('/admin/edit/event/new');
  };

  return (
    <main className={styles.main}>
      <section className="content">
        <AppBar className="appbar">
          <Toolbar>
            <Typography variant="h6" className={styles.title}>Events</Typography>
            <Button color="inherit" onClick={onNew} data-testid="newButton">New</Button>
          </Toolbar>
        </AppBar>
        <section className={styles.content}>
          <ul>
            {events.map(event => (
              <li className={styles.serviceItem} key={event.id}>
                <div className={styles.dateLine}>
                  <span>{event.date} {event.time}</span>
                  <DeleteButton onClick={() => onDeleteClick(event.id)} />
                </div>
                <div className={styles.mainItem}>
                  <Link to={`/admin/edit/event/${event.id}/`}>{event.name}</Link>
                </div>
                <div className={styles.subItem}>
                  {event.description}
                </div>
              </li>
            ))}
          </ul>
        </section>
        {(confirmDelete !== null) && (
          <ConfirmDialog title="Delete event?" confirmText="Delete" onConfirm={onDelete} onCancel={onCancel} />
        )}
      </section>
    </main>
  );
};

EventsEdit.propTypes = {
  history: pt.shape({
    push: pt.func
  }).isRequired,
};

export default EventsEdit;
