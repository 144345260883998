import React from 'react';
import pt from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

const ConfirmDialog = ({ cancelText, confirmText, onCancel, onConfirm, title }) => (
  <Dialog open onClose={onCancel}>
    <DialogTitle>{title}</DialogTitle>
    <DialogActions>
      <Button data-testid="cancelButton" onClick={onCancel} color="primary">{cancelText}</Button>
      <Button data-testid="confirmButton" onClick={onConfirm} color="primary">{confirmText}</Button>
    </DialogActions>
  </Dialog>
);

ConfirmDialog.propTypes = {
  cancelText: pt.string,
  confirmText: pt.string.isRequired,
  onCancel: pt.func.isRequired,
  onConfirm: pt.func.isRequired,
  title: pt.string.isRequired
};

ConfirmDialog.defaultProps = {
  cancelText: 'Cancel'
};

export default ConfirmDialog;
