/* eslint-disable react/no-danger */
import React, { useContext, useState } from 'react';
import marked from 'marked';
import { sanitize } from 'dompurify';

import styles from './AboutUs.module.css';
import { AuthContext } from './AuthContext';
import { CopyContext } from './CopyContext';
import CopyEdit from './CopyEdit';
import EditButton from './EditButton';

const AboutUs = () => {
  const [{ isAdmin }] = useContext(AuthContext);
  const [state,, actions] = useContext(CopyContext);
  const { about } = state;
  const [isEditingCopy, setEditingCopy] = useState(false);

  const onSave = (copy) => {
    actions.updateCopy('about', copy);
    setEditingCopy(false);
  };

  return (
    <article id="aboutus" className={styles.about}>
      <section className="content">
        {isAdmin && <EditButton onClick={() => setEditingCopy(true)} />}
        <h3>About Us</h3>
        <section dangerouslySetInnerHTML={{ __html: sanitize(marked(about)) }} />
        {isAdmin && isEditingCopy && (
          <CopyEdit
            copySection="about"
            open
            onClose={() => setEditingCopy(false)}
            onSave={onSave}
          />
        )}
      </section>
    </article>
  );
};

export default AboutUs;
