import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import { createMuiTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import firebaseInit from './firebase';

import { AuthProvider } from './AuthContext';
import { CopyProvider } from './CopyContext';
import { EventsProvider } from './EventsContext';
import { SermonProvider } from './SermonContext';

import Admin from './Admin';
import EventsEdit from './EventsEdit';
import EventsEventEdit from './EventsEventEdit';
import Privacy from './Privacy';
import SermonEdit from './SermonEdit';
import SermonList from './SermonList';
import SermonPlayer from './SermonPlayer';
import ServiceEdit from './ServiceEdit';
import ServiceTimeEdit from './ServiceTimeEdit';

firebaseInit();

const theme = createMuiTheme({
  typography: {
    htmlFontSize: 10 // Because we are using the 62.5% html font size
  }
});

/* istanbul ignore next */
const ScrollToTop = withRouter(({ children, location: { pathname } }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children || null;
});

/* istanbul ignore next */
const NotFound = () => '404 Not Found';

const root = document.getElementById('root');
/* istanbul ignore next */
const render = (root && root.hasChildNodes()) ? ReactDOM.hydrate : ReactDOM.render;
render(
  <ThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <AuthProvider>
        <CopyProvider>
          <EventsProvider>
            <SermonProvider>
              <Router>
                <ScrollToTop>
                  <Switch>
                    <Route path="/" exact component={App} />
                    <Route path="/sermons/" exact component={SermonList} />
                    <Route path="/sermons/:id/" component={SermonPlayer} />
                    <Route path="/admin/edit/event/" exact component={EventsEdit} />
                    <Route path="/admin/edit/event/:eventId" exact component={EventsEventEdit} />
                    <Route path="/admin/edit/sermon/:sermonId" exact component={SermonEdit} />
                    <Route path="/admin/edit/service/" exact component={ServiceEdit} />
                    <Route path="/admin/edit/service/:serviceId" exact component={ServiceTimeEdit} />
                    <Route path="/admin/" exact component={Admin} />
                    <Route path="/privacy/" exact component={Privacy} />
                    <Route path="" component={NotFound} />
                  </Switch>
                </ScrollToTop>
              </Router>
            </SermonProvider>
          </EventsProvider>
        </CopyProvider>
      </AuthProvider>
    </MuiPickersUtilsProvider>
  </ThemeProvider>,
  root
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
