import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import pt from 'prop-types';

export const INITIAL_STATE = {
  isAdmin: false,
  photoUrl: ''
};

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => (
    firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const res = await firebase.auth().currentUser.getIdTokenResult();
        setState({ isAdmin: !!res.claims.admin, photoUrl: user.photoURL });
      } else {
        setState({ isAdmin: false, photoUrl: '' });
      }
    })
  ), []);

  return (
    <AuthContext.Provider value={[state, setState]}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: pt.element.isRequired
};
