import React from 'react';
import pt from 'prop-types';

import { ReactComponent as PencilIcon } from './pencil.svg';
import styles from './EditButton.module.css';

const EditButton = ({ onClick, editStyle }) => (
  <div className={editStyle}>
    <button type="button" data-testid="editButton" onClick={onClick}><PencilIcon /></button>
  </div>
);

EditButton.defaultProps = {
  editStyle: styles.editButton
};

EditButton.propTypes = {
  editStyle: pt.string,
  onClick: pt.func.isRequired,
};

export default EditButton;
