import React, { useEffect, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';

import styles from './AudioPlayer.module.css';

const INITIAL_STATE = {
  currentTime: '0:00:00',
  duration: '0:00:00',
  isPaused: true,
  progress: '0%'
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setCurrentTime':
      return { ...state, currentTime: action.currentTime };
    case 'setDuration':
      return { ...state, duration: action.duration };
    case 'setIsPaused':
      return { ...state, isPaused: action.isPaused };
    case 'setProgress':
      return { ...state, progress: action.progress };
    /* istanbul ignore next */
    default:
      return state;
  }
};

const secondsToHms = (seconds) => {
  const mins = Math.floor(seconds / 60);
  const hours = Math.floor(mins / 60);
  const minsString = `0${mins % 60}`.slice(-2);
  const secondsString = `0${Math.floor(seconds) % 60}`.slice(-2);
  return `${hours}:${minsString}:${secondsString}`;
};

const setCurrentTime = (dispatch, currentTime) => {
  dispatch({ type: 'setCurrentTime', currentTime });
};

const setDuration = (dispatch, duration) => {
  dispatch({ type: 'setDuration', duration });
};

const setProgress = (dispatch, progress) => {
  dispatch({ type: 'setProgress', progress });
};

const setIsPaused = (dispatch, isPaused) => {
  dispatch({ type: 'setIsPaused', isPaused });
};

const AudioPlayer = ({ src }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const audioRef = useRef();

  useEffect(() => {
    const onTimeUpdate = (evt) => {
      const audio = evt.target;
      setCurrentTime(dispatch, secondsToHms(audio.currentTime));
      setProgress(dispatch, `${Math.round((audio.currentTime / audio.duration) * 100)}%`);
    };

    const onLoadedMetadata = (evt) => {
      setDuration(dispatch, secondsToHms(evt.target.duration));
    };

    const onAudioPlaying = () => {
      setIsPaused(dispatch, false);
    };

    const onAudioPaused = () => {
      setIsPaused(dispatch, true);
    };

    const onAudioEnded = () => {
      setIsPaused(dispatch, true);
    };

    const audio = audioRef.current;
    audio.addEventListener('timeupdate', onTimeUpdate);
    audio.addEventListener('loadedmetadata', onLoadedMetadata);
    audio.addEventListener('play', onAudioPlaying);
    audio.addEventListener('pause', onAudioPaused);
    audio.addEventListener('ended', onAudioEnded);
  }, [audioRef, dispatch]);

  const onBackSixty = () => {
    const audio = audioRef.current;
    audio.currentTime -= 60;
  };

  const onBackFive = () => {
    const audio = audioRef.current;
    audio.currentTime -= 5;
  };

  const onPlay = () => {
    const audio = audioRef.current;
    if (audio.paused) {
      audio.play();
    } else {
      audio.pause();
    }
  };

  const onStop = () => {
    const audio = audioRef.current;
    audio.pause();
    audio.currentTime = 0;
  };

  const onForwardFive = () => {
    const audio = audioRef.current;
    audio.currentTime += 5;
  };

  const onForwardSixty = () => {
    const audio = audioRef.current;
    audio.currentTime += 60;
  };

  const onTrackClick = (evt) => {
    const audio = audioRef.current;
    const rect = evt.currentTarget.getBoundingClientRect();
    const pos = (evt.clientX - rect.x) / rect.width;
    audio.currentTime = audio.duration * pos;
  };

  const onTrackKeydown = (evt) => {
    if (evt.keyCode === 32) {
      onForwardSixty();
    } else if (evt.keyCode === 8) {
      onBackSixty();
    }
  };

  const { currentTime, duration, isPaused, progress } = state;
  const playStyle = isPaused ? styles.play : styles.pause;
  return (
    <div className={styles.player}>
      <audio src={src} ref={audioRef} />
      <div className={styles.progressBar}>
        <div className={styles.elapsedTime} data-testid="elapsedTime">{currentTime}</div>
        <div
          className={styles.track}
          role="progressbar"
          tabIndex={0}
          onClick={onTrackClick}
          onKeyDown={onTrackKeydown}
          data-testid="track"
        >
          <div className={styles.elapsedTrack} style={{ width: progress }} data-testid="elapsedTrack" />
        </div>
        <div className={styles.duration} data-testid="duration">{ duration }</div>
      </div>
      <div className={styles.controlBar}>
        <button type="button" data-testid="backSixty" onClick={onBackSixty}>
          <div className={styles.backSixty} />
          Skip back 1 minute
        </button>
        <button type="button" data-testid="backFive" onClick={onBackFive}>
          <div className={styles.backFive} />
          Skip back 5 seconds
        </button>
        <button type="button" data-testid="play" onClick={onPlay}>
          <div className={playStyle} />
          Play/Pause
        </button>
        <button type="button" data-testid="stop" onClick={onStop}>
          <div className={styles.stop} />
          Stop
        </button>
        <button type="button" data-testid="forwardFive" onClick={onForwardFive}>
          <div className={styles.forwardFive} />
          Skip foward 5 seconds
        </button>
        <button type="button" data-testid="forwardSixty" onClick={onForwardSixty}>
          <div className={styles.forwardSixty} />
          Skip foward 1 minute
        </button>
      </div>
    </div>
  );
};

AudioPlayer.propTypes = {
  src: PropTypes.string.isRequired
};

export default AudioPlayer;
