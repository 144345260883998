import firebase from 'firebase/app';
import 'firebase/firestore';

const init = () => {
  const config = {
    apiKey: 'AIzaSyCyaElbIWBIMCkybElFGFghOQqtQbzvR4c',
    authDomain: 'tbc2019-56a90.firebaseapp.com',
    databaseURL: 'https://tbc2019-56a90.firebaseio.com',
    projectId: 'tbc2019-56a90',
    storageBucket: 'tbc2019-56a90.appspot.com',
    messagingSenderId: '687597471869',
    appId: '1:687597471869:web:eae3003faa7fb9a26e0914',
    measurementId: 'G-75RZD1SL1S'
  };
  firebase.initializeApp(config);
};

export default init;
