import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import fetchJsonp from 'fetch-jsonp';

import AudioPlayer from './AudioPlayer';
import Passage from './Passage';
import { SermonContext, fetchSermon } from './SermonContext';
import styles from './SermonPlayer.module.css';

const fetchJson = async (url) => {
  const resp = await fetchJsonp(url);
  if (!resp.ok) {
    return { error: 'Failed to fetch' };
  }
  return resp.json();
};

const processPassage = (passage, passageContent) => {
  const content = passageContent.book ? passageContent.book[0] : passageContent;
  const processedPassage = {
    passage,
    book: content.book_name,
    chapter: parseInt(content.chapter_nr, 10),
    verses: Object.keys(content.chapter).map(k => ({
      number: parseInt(content.chapter[k].verse_nr, 10),
      verse: content.chapter[k].verse
    }))
  };
  return processedPassage;
};

const SermonPlayer = ({ match }) => {
  const { params: { id } } = match;
  const [state, dispatch] = useContext(SermonContext);
  const [passages, setPassages] = useState([]);
  const { selectedSermon: sermon } = state;

  useEffect(() => {
    dispatch(fetchSermon(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (!sermon || !sermon.passages.length) {
      return;
    }
    Promise.all(sermon.passages.map(passage => fetchJson(`https://getbible.net/json?v=kjv&scrip=${passage}`)))
      .then((resolvedPassages) => {
        const results = sermon.passages
          .filter((p, idx) => !resolvedPassages[idx].error)
          .map((p, idx) => processPassage(p, resolvedPassages[idx]));
        setPassages(results);
      });
  }, [sermon, setPassages]);

  return (
    <article className={styles.sermonPlayer}>
      <section className="content">
        <header>
          <span className={styles.brand}>Tessentee Baptist Church</span>
          {sermon && <h1>{sermon.name}</h1>}
        </header>
        <main className={styles.content}>
          {(!sermon || (sermon.passages.length > 0 && passages.length === 0)) && (
            <div className={styles.msg}>Loading...</div>
          )}
          {sermon && (
            <>
              {sermon.passages.length === 0 && <div className={styles.msg}>No passages</div>}
              {passages.map(passage => <Passage passage={passage} key={passage.passage} />)}
              <AudioPlayer data-testid="audioPlayer" src={sermon.path} />
            </>
          )}
        </main>
      </section>
    </article>
  );
};

SermonPlayer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object
  }).isRequired
};

export default SermonPlayer;
