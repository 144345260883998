import React, { useContext, useEffect, useRef, useState } from 'react';
import pt from 'prop-types';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import uuid4 from 'uuid/v4';
import firebase from 'firebase/app';
import 'firebase/storage';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { DatePicker } from '@material-ui/pickers';

import { AuthContext } from './AuthContext';
import DeleteButton from './DeleteButton';
import { SermonContext, fetchSermon, saveSermon, updateSermon } from './SermonContext';
import styles from './SermonEdit.module.css';
import UploadWidget from './UploadWidget';

const DATE_FORMAT = 'yyyy-MM-dd';

const deleteAudioFile = filename => {
  const storageRef = firebase.storage().ref().child(`audio/${filename}`);
  return storageRef.delete();
};

const SermonEdit = ({ history, match: { params: { sermonId } } }) => {
  const [{ isAdmin }] = useContext(AuthContext);
  const [state, dispatch] = useContext(SermonContext);

  const didSave = useRef(false);

  const [abandonedFile, setAbandonedFile] = useState(null);
  const [canSave, setCanSave] = useState(true);
  const [nameError, setNameError] = useState(false);
  const [progress, setProgress] = useState(null);
  const [updatedSermon, setUpdatedSermon] = useState();

  const { selectedSermon } = state;

  // Fetch sermon
  useEffect(() => {
    if (sermonId !== 'new') {
      dispatch(fetchSermon(sermonId));
    }
  }, [dispatch, sermonId]);

  // Augment sermon or set default fields if new
  useEffect(() => {
    if (selectedSermon) {
      // The passages array needs unique ids for React list handling
      const augmentedSermon = { ...selectedSermon };
      augmentedSermon.passages = augmentedSermon.passages.map(text => ({ id: uuid4(), text }));
      setUpdatedSermon(augmentedSermon);
    }
    if (sermonId === 'new') {
      setUpdatedSermon({
        date: format(new Date(), DATE_FORMAT),
        name: '',
        filename: '',
        passages: [],
        path: ''
      });
    }
  }, [sermonId, selectedSermon]);

  // Keep an empty time slot at the bottom of the passages list for adding new passages
  useEffect(() => {
    if (!updatedSermon) {
      return;
    }

    const lastPassage = [...updatedSermon.passages].pop();
    if (updatedSermon.passages.length === 0 || lastPassage.text !== '') {
      const augmentedSermon = {
        ...updatedSermon,
        passages: [...updatedSermon.passages, { id: uuid4(), text: '' }]
      };
      setUpdatedSermon({ ...augmentedSermon });
    }
  }, [updatedSermon, setUpdatedSermon]);

  // Delete uploaded file on exit if we have a new record that is not saved
  useEffect(() => () => {
    if (abandonedFile) {
      if (!didSave.current && sermonId === 'new') {
        deleteAudioFile(abandonedFile).catch(error => console.log('unable to delete', error));
      }
    }
  }, [abandonedFile, sermonId]);

  // This is an admin only page
  if (!isAdmin) {
    history.push('/');
    return '';
  }

  const validateForm = () => {
    if (updatedSermon.name.trim().length === 0) {
      setNameError(true);
      return false;
    }
    setNameError(false);
    setCanSave(true);
    return true;
  };

  const onDelete = (id) => {
    const { passages } = updatedSermon;
    const passageToDelete = passages.findIndex(passage => passage.id === id);
    setUpdatedSermon({
      ...updatedSermon,
      passages: [...passages.slice(0, passageToDelete), ...passages.slice(passageToDelete + 1)]
    });
  };

  const onSave = async () => {
    setCanSave(false);
    if (!validateForm()) {
      return;
    }

    await dispatch(saveSermon(sermonId, { ...updatedSermon,
      passages: updatedSermon.passages.filter(passage => passage.text.trim() !== '').map(passage => passage.text)
    }));
    didSave.current = true;
    setAbandonedFile(null);
    history.goBack();
  };

  const onNameChange = (evt) => {
    updatedSermon.name = evt.target.value;
    validateForm();
    setUpdatedSermon({ ...updatedSermon });
  };

  const onPassageChange = (evt, id) => {
    const passages = [...updatedSermon.passages];
    passages.find(passage => passage.id === id).text = evt.target.value;
    setUpdatedSermon({ ...updatedSermon, passages });
  };

  const onUpdateDate = date => {
    updatedSermon.date = format(date, DATE_FORMAT);
    validateForm();
    setUpdatedSermon({ ...updatedSermon });
  };

  const onAudioUpload = file => {
    const storageRef = firebase.storage().ref().child(`audio/${file.name}`);
    const uploadTask = storageRef.put(file);
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      snapshot => {
        const uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(uploadProgress);
      },
      () => {
        setProgress(null);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then(url => {
          setUpdatedSermon({ ...updatedSermon, filename: file.name, path: url });
          setAbandonedFile(file.name);
          // If we are updating an exist record and we've uploaded a file, go ahead and
          // record this edit on the record
          if (sermonId !== 'new') {
            dispatch(updateSermon(sermonId, { filename: file.name, path: url }));
          }
        });
      });
  };

  const onAudioDelete = filename => {
    deleteAudioFile(filename)
      .then(() => {
        setUpdatedSermon({ ...updatedSermon, filename: '', path: '' });
        // If we are updating an exist record and we've deleted its audio file, go ahead and
        // record this edit on the record
        /* istanbul ignore else */
        if (sermonId !== 'new') {
          dispatch(updateSermon(sermonId, { filename: '', path: '' }));
        }
      })
      .catch(() => console.log('delete failed'));
  };

  if (!updatedSermon) {
    return '';
  }

  return (
    <main className={styles.main}>
      <section className="content">
        <AppBar className="appbar">
          <Toolbar>
            <Typography variant="h6" className={styles.title}>Sermons</Typography>
            {canSave && <Button color="inherit" data-testid="saveButton" onClick={onSave}>Save</Button>}
          </Toolbar>
        </AppBar>
        <form className={styles.content}>
          <TextField
            inputProps={{ 'data-testid': 'sermonNameInput' }}
            margin="dense"
            label="Sermon name"
            value={updatedSermon.name}
            onChange={onNameChange}
            error={nameError}
          />
          <DatePicker
            inputProps={{ 'data-testid': 'dateInput' }}
            margin="dense"
            label="Date"
            format="yyyy-MM-dd"
            value={parseISO(updatedSermon.date)}
            onChange={onUpdateDate}
          />
          <div className={styles.passagesHeader}>Passages</div>
          <ul data-testid="passagesList">
            {updatedSermon.passages.map(passage => (
              <li className={styles.passagesRow} key={passage.id}>
                <TextField
                  label="Passage"
                  margin="dense"
                  value={passage.text}
                  onChange={evt => onPassageChange(evt, passage.id)}
                />
                {passage.text !== '' ? (
                  <DeleteButton onClick={() => onDelete(passage.id)} />
                ) : (
                  <div className={styles.placeholder} />
                )}
              </li>
            ))}
          </ul>
          <div className={styles.passagesHeader}>Sermon audio</div>
          <UploadWidget
            filename={updatedSermon.filename}
            path={updatedSermon.path}
            progress={progress}
            onDelete={onAudioDelete}
            onUpload={onAudioUpload}
          >
            <div>Click here</div>
            <div>to upload sermon</div>
          </UploadWidget>
        </form>
      </section>
    </main>
  );
};

SermonEdit.propTypes = {
  history: pt.shape({
    goBack: pt.func,
    push: pt.func
  }).isRequired,
  match: pt.shape({
    params: pt.shape({
      sermonId: pt.string
    })
  }).isRequired
};

export default SermonEdit;
