import React from 'react';
import styles from './MainHeader.module.css';

import MobileMenu from './MobileMenu';

const MainHeader = () => (
  <header className={styles.header}>
    <section className="content">
      <MobileMenu />
      Tessentee Baptist Church
      <a href="#directions" className={styles.cta}>Come worship with us</a>
    </section>
  </header>
);

export default MainHeader;
