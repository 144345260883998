import React, { useCallback, useReducer } from 'react';
import firebase from 'firebase/app';
import pt from 'prop-types';

export const INITIAL_STATE = {
  selectedSermon: null,
  sermons: []
};

export const SermonContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'receive_sermons':
      return { ...state, sermons: action.sermons };
    case 'receive_selected_sermon':
      return { ...state, selectedSermon: action.sermon };
    /* istanbul ignore next */
    default:
      /* istanbul ignore next */
      return state;
  }
};

export const fetchSermons = (limit = 0) => async (dispatch) => {
  const docs = await firebase.firestore().collection('sermons').orderBy('date', 'desc').get();
  const sermons = [];
  docs.forEach(doc => sermons.push({ id: doc.id, ...doc.data() }));
  if (limit > 0) {
    dispatch({ type: 'receive_sermons', sermons: sermons.slice(0, limit) });
  } else {
    dispatch({ type: 'receive_sermons', sermons });
  }
};

export const deleteSermon = (id) => () => firebase.firestore().collection('sermons').doc(id).delete();

export const fetchSermon = sermonId => async (dispatch) => {
  const doc = await firebase.firestore().collection('sermons').doc(sermonId).get();
  dispatch({ type: 'receive_selected_sermon', sermon: doc.data() });
};

export const saveSermon = (id, sermon) => () => {
  if (id === 'new') {
    return firebase.firestore().collection('sermons').add(sermon);
  }
  return firebase.firestore().collection('sermons').doc(id).set(sermon);
};

export const updateSermon = (id, sermon) => (
  firebase.firestore().collection('sermons').doc(id).update(sermon)
);

const getThunkedDispatch = dispatch => {
  const thunkedDispatch = action => {
    if (typeof action === 'function') {
      return action(thunkedDispatch);
    }
    return dispatch(action);
  };
  return thunkedDispatch;
};

export const SermonProvider = ({ children }) => {
  const [state, reactDispatch] = useReducer(reducer, INITIAL_STATE);
  const dispatch = useCallback(getThunkedDispatch(reactDispatch), [reactDispatch]);

  return (
    <SermonContext.Provider value={[state, dispatch]}>
      {children}
    </SermonContext.Provider>
  );
};

SermonProvider.propTypes = {
  children: pt.element.isRequired
};
